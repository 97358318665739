<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link to="/" class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">XMLTV Source</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <static-content :content-code="leftContentCode">
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img fluid :src="imgUrl" alt="Login V2" />
          </div>
        </static-content>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="10" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1 auth-title">
            Welcome to XMLTV Source! 👋
          </b-card-title>
          <slot />
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { BCardTitle, BCol, BImg, BLink, BRow } from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store";
import StaticContent from "@/layouts/components/static-content-editor/StaticContent";

export default {
  components: {
    StaticContent,
    BRow,
    BCol,
    BLink,
    BCardTitle,
    BImg,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  props: {
    leftContentCode: {
      type: String,
      required: false,
      default: "auth-left-content",
    },
  },
  data() {
    return {
      sideImg: require("@/assets/images/logo/Frame.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

@media (max-width: 991px) {
  .auth-title {
    margin-top: 4em;
  }
}
</style>
